import React from "react"
import styled from "styled-components"

import { Wrapper } from "./styles/container"
import "./styles/klaus-gridski.css"

const BannerStyle = styled.div`
  background-color: var(--light-green);
  padding: 5rem 0;
  color: white;
  font-family: "PT Serif";
  font-size: 2rem;
`

const Mail = styled.a`
  display: flex;
  align-items: center;
  font-style: italic;
  ::before {
    display: inline-block;
    content: "";
    border-top: 1px solid white;
    width: 3rem;
    margin: 0 1rem;
  }
  margin-top: 5rem;
  /* Width in PX > 768px */
  @media only screen and (min-width: 48em) {
    margin-top: 0;
  } /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    :hover {
      color: var(--dark-green);
    }
  }
`

const Banner = () => (
  <BannerStyle>
    <Wrapper className="flex vertical md-horizontal between">
      <i>Jetzt Beratungstermin vereinbaren!</i>
      <Mail
        target="_blank"
        title="Mail schreiben"
        href="mailto:hallo@christinapabst.de"
      >
        hallo@christinapabst.de
      </Mail>
    </Wrapper>
  </BannerStyle>
)

export default Banner
