import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import "../components/styles/klaus-gridski.css"
import { Wrapper } from "../components/styles/container"

import T from "../images/icons/training.svg"
import C from "../images/icons/coaching.svg"
import M from "../images/icons/mediation.svg"
import CR from "../images/icons/christina.svg"

const Icon = styled.img`
  width: 70px;
  height: 70px;
`

const Headline = styled.p`
  font-weight: 700;
  margin: 2rem 0 0 0;
`

const Explain = styled.p`
  max-width: 350px;
  text-align: center;
`

const Subject = styled.div`
  margin: 5rem 0;
  /* Width in PX > 920px */
  @media only screen and (min-width: 62em) {
    margin: 3rem 2rem;
  }
`

const Subjects = () => (
  <Wrapper className="flex vertical bg-horizontal around content-visibility">
    <Subject className=" flex vertical v-center">
      <Link to="/about">
        <Icon alt="CSymbol" src={CR} />
      </Link>
      <Headline>Christina</Headline>
      <Explain>Ihr Coach, Mediator und Trainer.</Explain>
    </Subject>
    <Subject className=" flex vertical v-center">
      <Link to="/coaching">
        <Icon alt="Coaching Symbol" src={C} />
      </Link>
      <Headline>Coaching</Headline>
      <Explain>
        Durch strukturierte Gespräche wird die Entwicklung eigener Lösungen
        begleitet.
      </Explain>
    </Subject>
    <Subject className=" flex vertical v-center">
      <Link to="/mediation">
        <Icon alt="Mediations Symbol" src={M} />
      </Link>
      <Headline>Mediation</Headline>
      <Explain>
        Ziel ist die konstruktive Beilegung eines Konfliktes durch die
        Beteiligung allparteilicher Dritter.
      </Explain>
    </Subject>
    <Subject className=" flex vertical v-center">
      <Link to="/training">
        <Icon alt="Training Symbol" src={T} />
      </Link>
      <Headline>Training</Headline>
      <Explain>
        Die planmäßige und systematische Realisation von Maßnahmen zur
        nachhaltigen Erreichung von Zielen.
      </Explain>
    </Subject>
  </Wrapper>
)

export default Subjects
