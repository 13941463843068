import React from "react"
import styled from "styled-components"

import { Section, Wrapper, Paragraph } from "../components/styles/container"
import "../components/styles/klaus-gridski.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Subjects from "../components/subjects"

import Hero from "../images/otherhero.jpg"
import HeroTab from "../images/otherhero_tablet.jpg"
import HeroMob from "../images/otherhero_mobile.jpg"
import Sign from "../images/sign.svg"

const SignStyle = styled.img`
  max-width: 200px;
  height: 100%;
  max-height: 350px;
  /* Width in PX > 768px */
  @media only screen and (min-width: 48em) {
    max-width: 250px;
  }
  /* Width in PX > 1200px */
  @media only screen and (min-width: 75em) {
    max-width: 300px;
  }
`

const ParagraphStyle = styled(Paragraph)`
  text-align: center;
  margin-bottom: 5rem;
`

const HeroStyle = styled.img`
  width: 100%;
  height: 35vh;
  object-fit: cover;
  object-position: center center;
  /* Width in PX > 768px */
  @media only screen and (min-width: 48em) {
    height: 40vh;
  }
  /* Width in PX > 1200px */
  @media only screen and (min-width: 75em) {
    height: 60vh;
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Startseite" />
      <picture>
        <source
          role="presentation"
          srcset={HeroMob}
          media="(max-width: 30em)"
        />
        <source
          role="presentation"
          srcset={HeroTab}
          media="(min-width: 30em) and (max-width: 62em)"
        />
        <HeroStyle role="presentation" src={Hero} />
      </picture>
      <Section>
        <Wrapper className="flex vertical v-center">
          <h2>Schneller ans Ziel - Gemeinsam</h2>
          <ParagraphStyle>
            Mein Ziel: Sie wachsen zu sehen. Ich denke mit, weiter und frisch.
            Mit Leidenschaft und Berufserfahrung, Kompetenz und Ideen, Loyalität
            und Leichtigkeit.
          </ParagraphStyle>

          <SignStyle alt="Christina Pabst Bildmarke" src={Sign} />
        </Wrapper>
      </Section>
      <Section>
        <Subjects />
      </Section>

      <Banner />
    </Layout>
  )
}

export default IndexPage
